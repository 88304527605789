<template>
    <div class="register-success-tbf forgot-modal">
        <div class="success-modal">
            <div class="content-modal">
                <div class="content-icon forgot">
                    <img src="/build/flappy/bird_forgot.png" class="forgot"/>
                </div>
                <div class="title forgot">
                    <span>{{ $t('forgot_password.title') }}</span>
                    <div class="btn-tbf only-icon white" @click="redirect()">
                        <icon-close />
                    </div>
                </div>
                <div class="container-content-modal">
                    <div class="form-section-register">
                        <div class="container-message forgot">
                            {{ $t('forgot_password.description') }}
                        </div>
                        <div class="input-login-group-tbf w-tbf-100" v-bind:class="{has_error: $v.email.$error, complete: email != '' && !$v.email.$error}">
                            <div class="label-input">
                                <label>{{$t('forgot_password.email')}}</label>
                            </div>
                            <div class="input-box">
                                <div class="icon-left"><icon-email /></div>
                                <div class="icon-right" v-if="email != ''" @click.stop="email = ''"><icon-close /></div>
                                <input type="email" :placeholder="$t('forgot_password.email-placeholder')" class="input-text" v-model="email">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="action-form">
                    <button id="buttonSubmit" type="submit" class="btn-tbf blue center" @click="resetPassword">
                        <div class="loader"></div>
                        <div class="text">{{ $t('forgot_password.button-action') }}</div>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import IconClose from '../Icons/Close'
import IconEmail from '../Icons/Email'

import { required, minLength, email } from 'vuelidate/lib/validators'

export default {
	data(){
		return {
            email: ""
		}
    },
    components: {
        IconClose,
        IconEmail
    },
    validations: {
		email: {required, email},
	},
	methods: {
        resetPassword() {
            var buttonName = 'buttonSubmit'
            var btnSubmit = document.getElementById(buttonName);
            var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
            var btnSubmitText = document.querySelector(`#${buttonName} .text`)

            btnSubmit.disabled = true

            btnSubmit.classList.add('loading')
            btnSubmitLoader.classList.add('onProgress')
            btnSubmitText.innerHTML = this.$t('btn-submit.loading')

            this.$v.$touch()
            if(this.$v.$invalid){
                btnSubmitLoader.classList.add('finish')
                setTimeout(()=>{
                    btnSubmitText.innerHTML = this.$t('btn-submit.error')
                    btnSubmit.classList.add('error')
                    btnSubmitLoader.classList.remove('onProgress', 'finish')
                    btnSubmit.classList.remove('loading')
                    setTimeout(()=>{
                        btnSubmit.classList.remove('error')
                        btnSubmitText.innerHTML = this.$t('forgot_password.button-action')
                        btnSubmit.disabled = false
                    }, 1000)
                }, 300)
            }else{
                axios.post("/auth/reset-password", {email: this.email})
                .then(result => {
                    btnSubmitLoader.classList.add('finish')
                    setTimeout(()=>{
                        btnSubmitText.innerHTML = this.$t('btn-submit.success')
                        btnSubmit.classList.add('completed')
                        btnSubmitLoader.classList.remove('onProgress', 'finish')
                        btnSubmit.classList.remove('loading')
                        setTimeout(()=>{
                            btnSubmit.classList.remove('completed')
                            this.$router.push({ name: 'password-forgot-success', query: { email: this.email }});
                        }, 1000)
                    }, 300)
                }).catch(error => {
                    btnSubmitLoader.classList.add('finish')
                    setTimeout(()=>{
                        btnSubmitText.innerHTML = this.$t('btn-submit.error')
                        btnSubmit.classList.add('error')
                        btnSubmitLoader.classList.remove('onProgress', 'finish')
                        btnSubmit.classList.remove('loading')
                        setTimeout(()=>{
                            btnSubmit.classList.remove('error')
                            btnSubmitText.innerHTML = this.$t('forgot_password.button-action')
                            btnSubmit.disabled = false
                        }, 1000)
                    }, 300)
                });
            }
        },
        redirect() {
            this.$router.push("/login");
        }
	}
}
</script>

<style lang="scss">
    .forgot-modal{
        .content-modal{
            .action-form{
                margin-top: 0;
            }
        }
    }
</style>